/**
 * @file It contains scripts for domains overview.
 */
import { mapGetters, mapActions, mapMutations } from "vuex"
import { getListOfStrings } from "@/utils"
import { getFootersForTable } from "@/utils/table"
import { TABLE_NAMES } from "@/constants"
import  ThemisDomainAdd  from "@/components/domain/add"

export default {
  name      : "Domains",
  components: {
    ThemisDomainAdd
  },
  data() {
    return {
      filters              : undefined,
      search               : undefined,
      showAddDomainDialog  : false,
      isDomainNameDuplicate: false,
      newlyAddedDomainName : undefined
    }
  },
  methods: {
    ...mapActions({
      addDomain: "domains/addDomain",
      notify   : "shared/notify"
    }),
    ...mapMutations({
      setDomainAddError: "domains/setDomainAddError"
    }),
    /**
     * This method is used custom filtering for vuetify data table.
     * This will be called for every cell value in the table.
     * @param {*} value is content of each cell in a the table
     */
    customFilterForTable(value) {
      return this.filters.findIndex(element => value?.toString().includes(element)) + 1
    },
    handleAddDomainEvent(domain) {
      this.newlyAddedDomainName = domain.name
      this.addDomain(domain)
    }
  },
  computed: {
    ...mapGetters({
      domains       : "domains/domains",
      isAddingDomain: "domains/isAddingDomain",
      isDomainAdded : "domains/isDomainAdded",
      domainAddError: "domains/domainAddError"
    }),
    domainsForTable: function() {
      return this.domains.map(domain => ({
        id      : domain.id,
        name    : domain.name,
        archived: domain.archived ? this.$t("351") : undefined
      }))
    },
    headersForTable() {
      return this.$TABLES.DOMAINS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.DOMAINS, this.$t.bind(this))
    },
    itemsForSearch() {
      return getListOfStrings(this.domainsForTable, ["id"])
    }
  },
  watch: {
    filters: function(newFilters) {
      this.search = newFilters ? newFilters.toString() : undefined
    },
    isDomainAdded: {
      handler: function(value) {
        if (value) {
          this.showAddDomainDialog = false
          this.notify({
            type      : "success",
            text      : "430",
            parameters: {
              name: this.newlyAddedDomainName
            }
          })
          this.newlyAddedDomainName = undefined
        }
      }
    }
  }
}
